import React from 'react';
import {
    BrowserRouter as Router, Switch, Route,
} from 'react-router-dom';
import asyncComponent from 'src/utils/asyncComponent';
import Warp from '../views/warp'
const Notice = asyncComponent(() => import('src/views/Notice/Notice'));
const Detail = asyncComponent(() => import('src/views/Details'));
const Answer = asyncComponent(() => import('src/pages/Evaluation/Answer'));
const Report = asyncComponent(() => import('src/pages/Evaluation/Report'));
const Index = asyncComponent(() => import('src/pages/Evaluation/index'));

const RootRouter = () => (
    <Router path="/">
        <Warp>
            <Switch>
                <Route exact path="/" component={Index} />
                <Route exact path="/evaluation/notice" component={Notice} />
                <Route exact path="/evaluation/answer" component={Answer} />
                <Route exact path="/evaluation/details" component={Detail} />
                <Route exact path="/evaluation/report" component={Report} />
            </Switch>
        </Warp>
    </Router>
);

export default RootRouter;
