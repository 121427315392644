import React, { useEffect } from 'react'
import * as qs from 'querystring';
import { set } from '../globalData'
import { withRouter } from 'react-router-dom'
const Warp = (props) => {
    useEffect(() => {
        const query = qs.parse(props.location.search.split('?')[1]);
        /** 微信小程序跳转过来的，会有此参数 */
        const { classOpenid, quxinliUserid, userSign } = query;
        if (userSign) {
            set("userId", userSign)
        }
        if (classOpenid) {
            set("classOpenid", classOpenid)
        }
        if (quxinliUserid) {
            set("quxinliUserid", quxinliUserid)
        }
    }, [props.location.search])
    return <>
        {props.children}
    </>
}
export default withRouter(Warp)
