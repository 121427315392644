import React from 'react';
import ReactDOM from 'react-dom';
import RootRouter from './router/index';
import './reset.less';
import './index.less';

ReactDOM.render(
  <React.StrictMode>
    <RootRouter />
  </React.StrictMode>,
  document.getElementById('root')
);

